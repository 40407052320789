<template>
  <v-container fluid>
    <v-card v-if="userType === 1 || userType === 2" class="py-0" flat>
      <v-card-text class="mx-0 px-0">
        <v-row dense>
          <v-col
            v-if="
              $store.getters['participantsStore/getParticipantsSelected']
                .length === 0
            "
            cols="12"
          >
            <span class="error--text">
              At least one supervisor must be selected.
            </span>
          </v-col>
          <v-col cols="12" sm="12">
            <!--Funding-->
            <span
              v-if="userType === 1 && section === 1"
              class="subtitle-1 font-weight-bold black--text"
              >T-2 Project Researchers who are PIs/CoPIs and Key Personnel for
              this Proposal:</span
            >
            <!--Publication-->
            <span
              v-if="userType === 1 && section === 2"
              class="subtitle-1 font-weight-bold black--text"
              >T-2 Project Researchers who are Co-authors on this
              Publication:</span
            >
            <!--Patent-->
            <span
              v-if="userType === 1 && section === 3"
              class="subtitle-1 font-weight-bold black--text"
              >T-2 Project Researchers who are Co-applicants on this
              Patent:</span
            >
            <!--Presentation-->
            <span
              v-if="userType === 1 && section === 4"
              class="subtitle-1 font-weight-bold black--text"
              >T-2 Project Researchers who are Presenters/Co-presenters for this
              Presentation:</span
            >
            <!--Outcome-->
            <span
              v-if="userType === 1 && section === 5"
              class="subtitle-1 font-weight-bold black--text"
              >T-2 Project Researchers who are Partners/Co-developers for this
              Outcome:</span
            >
            <!--User Trainees Advisors-->
            <span
              v-if="userType === 1 && section === 6"
              class="subtitle-1 font-weight-bold black--text"
              >Advisors</span
            >

            <br v-if="userType === 1" />

            <!--Funding-->
            <span
              v-if="userType === 2 && section === 1"
              class="subtitle-1 font-weight-bold black--text"
              >T-2 Project Trainees who are PIs/CoPIs and Key Personnel for this
              Proposal:</span
            >
            <!--Publication-->
            <span
              v-if="userType === 2 && section === 2"
              class="subtitle-1 font-weight-bold black--text"
              >T-2 Project Trainees who are Co-authors on this
              Publication:</span
            >
            <!--Patent-->
            <span
              v-if="userType === 2 && section === 3"
              class="subtitle-1 font-weight-bold black--text"
              >T-2 Project Trainees who are Co-applicants on this Patent:</span
            >
            <!--Presentation-->
            <span
              v-if="userType === 2 && section === 4"
              class="subtitle-1 font-weight-bold black--text"
              >T-2 Project Trainees who are Presenters/Co-presenters for this
              Presentation:</span
            >
            <!--Outcome-->
            <span
              v-if="userType === 2 && section === 5"
              class="subtitle-1 font-weight-bold black--text"
              >T-2 Project Trainees who are Partners/Co-developers for this
              Outcome:</span
            >

            <br v-if="userType === 2" />

            <!--Funding-->
            <span v-if="section === 1" class="subtitle-1 black--text"
              >From list on left, select all names that apply using the plus
              symbol by the name and then select the role for each individual
              you selected as specified in the proposal.</span
            >
            <!--Publication, Patent, Presentation, Outcome-->
            <span
              v-if="
                section === 2 || section === 3 || section === 4 || section === 5
              "
              class="subtitle-1 black--text"
              >From list on left, select all names that apply using the plus
              symbol by the name.</span
            >
          </v-col>
        </v-row>
        <v-row dense>
          <v-col v-if="!readonly" class="py-0" cols="6" sm="6">
            <v-subheader class="px-0 pr-7" color="accent">
              {{
                `Project ${
                  userType === 1
                    ? "Researchers"
                    : userType === 2
                    ? "Trainees"
                    : ""
                }`
              }}

              <v-spacer />

              <!--  Filter Icon -->
              <v-menu
                v-model="filterMenu"
                offset-y
                right
                :close-on-content-click="false"
              >
                <template #activator="{ on: menu }">
                  <v-btn
                    :color="showFiltered() ? 'primary' : 'clear'"
                    :outlined="!showFiltered()"
                    :dark="showFiltered()"
                    depressed
                    rounded
                    :text="!showFiltered()"
                    v-on="menu"
                  >
                    <v-icon left small> fal fa-filter </v-icon>Filter
                  </v-btn>
                </template>

                <v-card width="400px">
                  <v-card-title>
                    <v-spacer />
                    <v-btn
                      v-if="showFiltered()"
                      text
                      rounded
                      outlined
                      block
                      @click="clearFilter()"
                    >
                      <v-icon small left> fal fa-times </v-icon>Clear Filters
                    </v-btn>
                  </v-card-title>
                  <v-card-text class="mx-0 py-0">
                    <v-container fluid>
                      <v-row dense>
                        <!--First Name-->
                        <v-col cols="12" sm="12">
                          <v-text-field
                            v-if="userType === 1"
                            v-model="researcher_name_first"
                            label="First Name"
                            placeholder=" "
                          />
                          <v-text-field
                            v-if="userType === 2"
                            v-model="trainee_name_first"
                            label="First Name"
                            placeholder=" "
                          />
                        </v-col>

                        <!--Last Name-->
                        <v-col cols="12" sm="12">
                          <v-text-field
                            v-if="userType === 1"
                            v-model="researcher_name_last"
                            label="Last Name"
                            placeholder=" "
                          />
                          <v-text-field
                            v-if="userType === 2"
                            v-model="trainee_name_last"
                            label="Last Name"
                            placeholder=" "
                          />
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions class="mx-4 pb-4">
                    <v-btn
                      color="primary"
                      block
                      rounded
                      @click="findParticipants()"
                    >
                      <v-icon small left> far fa-search </v-icon>Find
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-menu>
            </v-subheader>

            <!---------- Researchers Selection List ---------->
            <v-list v-if="userType === 1" dense>
              <template
                v-for="(researcher, index) in participantsStore.researchers"
              >
                <!--<template v-if="researcher.id !== usersStore.activeUser.id || researcher.id === usersStore.activeUser.id && usersStore.activeUserType === 1 || researcher.id === usersStore.activeUser.id && usersStore.activeUserType === 2">-->
                <v-list-item
                  :key="`researcher-${index}`"
                  :disabled="participants.includes(researcher.id)"
                  dense
                >
                  <v-list-item-title v-text="researcher.name_reverse" />
                  <v-list-item-action>
                    <v-btn
                      v-if="roleSelector !== 1"
                      :disabled="
                        participants.includes(researcher.id) ||
                          researcher.id === usersStore.activeUser.id
                      "
                      icon
                      @click="
                        addParticipant({
                          type: 1,
                          id: researcher.id,
                          role: '',
                          name_reverse: researcher.name_reverse,
                        })
                      "
                    >
                      <v-icon color="primary">
                        far fa-plus
                      </v-icon>
                    </v-btn>
                    <v-menu v-else bottom offset-y>
                      <template #activator="{ on }">
                        <v-btn
                          :disabled="participants.includes(researcher.id)"
                          icon
                          v-on="on"
                        >
                          <v-icon color="primary">
                            far fa-plus
                          </v-icon>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item
                          v-for="(item,
                          roleIndex) in valueListsStore.roleValueList"
                          :key="`role-${roleIndex}`"
                          @click="
                            addParticipant({
                              type: 1,
                              id: researcher.id,
                              role: item.role,
                              name_reverse: researcher.name_reverse,
                            })
                          "
                        >
                          <v-list-item-title>{{ item.name }}</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-list-item-action>
                </v-list-item>

                <v-divider
                  v-if="index + 1 < participantsStore.researchers.length"
                  :key="`researcher-${index}-divider`"
                />
              </template>
            </v-list>

            <!---------- Trainees Selection List ---------->
            <v-list v-if="userType === 2" dense>
              <template v-for="(trainee, index) in participantsStore.trainees">
                <!--<template v-if="trainee.id !== usersStore.activeUser.id || (trainee.id === usersStore.activeUser.id && usersStore.activeUserType === 3)">-->
                <template>
                  <v-list-item
                    :disabled="participants.includes(trainee.id)"
                    dense
                  >
                    <v-list-item-title v-text="trainee.name_reverse" />
                    <v-list-item-action>
                      <v-btn
                        v-if="roleSelector !== 1"
                        :disabled="participants.includes(trainee.id)"
                        icon
                        @click="
                          addParticipant({
                            type: 2,
                            id: trainee.id,
                            role: '',
                            name_reverse: trainee.name_reverse,
                          })
                        "
                      >
                        <v-icon color="primary">
                          far fa-plus
                        </v-icon>
                      </v-btn>
                      <v-menu v-else bottom offset-y>
                        <template #activator="{ on }">
                          <v-btn
                            :disabled="
                              participants.includes(trainee.id) ||
                                trainee.id === usersStore.activeUser.id
                            "
                            icon
                            v-on="on"
                          >
                            <v-icon color="primary">
                              far fa-plus
                            </v-icon>
                          </v-btn>
                        </template>
                        <v-list>
                          <v-list-item
                            v-for="(item,
                            roleIndex) in valueListsStore.roleValueList"
                            :key="`role-${roleIndex}`"
                            @click="
                              addParticipant({
                                type: 2,
                                id: trainee.id,
                                role: item.role,
                                name_reverse: trainee.name_reverse,
                              })
                            "
                          >
                            <v-list-item-title>{{
                              item.name
                            }}</v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </v-list-item-action>
                  </v-list-item>

                  <v-divider
                    v-if="index + 1 < participantsStore.trainees.length"
                  />
                </template>
              </template>
            </v-list>
          </v-col>

          <!---------- Researchers Selected List ---------->
          <v-col class="py-0" :cols="readonly ? 12 : 6" :sm="readonly ? 12 : 6">
            <v-subheader class="px-0">
              {{
                `Selected ${
                  userType === 1
                    ? "Researchers"
                    : userType === 2
                    ? "Trainees"
                    : ""
                }`
              }}
            </v-subheader>

            <v-list v-if="userType === 1" dense>
              <template v-for="(participant, index) in participants_selected">
                <!--<template v-if="participant.type === 1 && participants.includes(participant.id) && (participant.id !== usersStore.activeUser.id || 
                participant.type === 1 && participant.id === usersStore.activeUser.id && usersStore.activeUserType === 1)|| 
                participant.type === 1 && participants.includes(participant.id) && (participant.id !== usersStore.activeUser.id || 
                participant.type === 1 && participant.id === usersStore.activeUser.id && usersStore.activeUserType === 2)">-->
                <template
                  v-if="
                    participant.type === 1 &&
                      participants.includes(participant.id)
                  "
                >
                  <v-list-item dense>
                    <v-list-item-content>
                      <v-list-item-title v-text="participant.name_reverse" />
                    </v-list-item-content>
                    <v-list-item-content>
                      <v-autocomplete
                        v-if="roleSelector === 1"
                        v-model="participant.role"
                        :items="valueListsStore.roleValueList"
                        item-text="name"
                        item-value="role"
                        label="Role required if name selected. *"
                        placeholder=" "
                        hide-details
                        dense
                        required
                        :rules="rules.not_empty"
                        :readonly="readonly"
                      />
                    </v-list-item-content>
                    <v-list-item-action>
                      <!-- <v-btn 
                        icon
                        :disabled="readonly || participant.id === usersStore.activeUser.id"
                        @click="removeParticipant({id: participant.id })"
                      > -->
                      <v-btn
                        icon
                        :disabled="readonly"
                        @click="removeParticipant({ id: participant.id })"
                      >
                        <v-icon
                          v-if="
                            participant.id !== usersStore.activeUser.id ||
                              !readonly
                          "
                          color="red"
                        >
                          fal fa-minus-circle
                        </v-icon>
                      </v-btn>
                    </v-list-item-action>
                  </v-list-item>

                  <v-divider v-if="index + 1 < participants.length" />
                </template>
              </template>
            </v-list>

            <!---------- Trainees Selected List ---------->
            <v-list v-if="userType === 2" dense>
              <template v-for="(participant, index) in participants_selected">
                <!--<template v-if="participant.type === 2 && participants.includes(participant.id) && (participant.id !== usersStore.activeUser.id || 
                participant.type === 2 && participant.id === usersStore.activeUser.id && usersStore.activeUserType === 3)">-->
                <template
                  v-if="
                    participant.type === 2 &&
                      participants.includes(participant.id)
                  "
                >
                  <v-list-item dense>
                    <v-list-item-content>
                      <v-list-item-title v-text="participant.name_reverse" />
                    </v-list-item-content>
                    <v-list-item-content>
                      <v-autocomplete
                        v-if="roleSelector === 1"
                        v-model="participant.role"
                        :items="valueListsStore.roleValueList"
                        item-text="name"
                        item-value="role"
                        placeholder=" "
                        hide-details
                        dense
                        required
                        :rules="rules.not_empty"
                        :readonly="readonly"
                      />
                    </v-list-item-content>
                    <v-list-item-action>
                      <!-- <v-btn
                        icon
                        :disabled="readonly || participant.id === usersStore.activeUser.id"
                        @click="removeParticipant({id: participant.id })"
                      > -->
                      <v-btn
                        icon
                        :disabled="readonly"
                        @click="removeParticipant({ id: participant.id })"
                      >
                        <v-icon
                          v-if="
                            participant.id !== usersStore.activeUser.id ||
                              !readonly
                          "
                          color="red"
                        >
                          fal fa-minus-circle
                        </v-icon>
                      </v-btn>
                    </v-list-item-action>
                  </v-list-item>

                  <v-divider v-if="index + 1 < participants.length" />
                </template>
              </template>
            </v-list>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <!---------- Non T2 ---------->
    <v-card
      v-if="userType === 3 && section !== 6"
      class="py-0 overflow-y-auto"
      flat
    >
      <v-toolbar flat>
        <v-toolbar-title>
          <!--Funding-->
          <span
            v-if="section === 1"
            class="subtitle-1 font-weight-bold black--text"
            >Non-T-2 Project Participants who are PIs/CoPIs and Key Personnel
            for this Proposal:
          </span>
          <!--Publication-->
          <span
            v-if="section === 2"
            class="subtitle-1 font-weight-bold black--text"
            >Non-T-2 Project Participants who are Co-authors on this
            Publication:
          </span>
          <!--Patents-->
          <span
            v-if="section === 3"
            class="subtitle-1 font-weight-bold black--text"
            >Non-T-2 Project Participants who are Co-applicants on this Patent:
          </span>
          <!--Presentations-->
          <span
            v-if="section === 4"
            class="subtitle-1 font-weight-bold black--text"
            >Non-T-2 Project Participants who are Presenters/Co-presenters for
            this Presentation:
          </span>
          <!--Outcomes-->
          <span
            v-if="section === 5"
            class="subtitle-1 font-weight-bold black--text"
            >Non-T-2 Project Participants who are Partners/Co-developers for
            this Outcome:
          </span>
        </v-toolbar-title>
        <v-spacer />
        <v-btn v-if="!readonly" rounded color="primary" @click="addNonT2()">
          <v-icon small left> fal fa-plus </v-icon>Add
        </v-btn>
      </v-toolbar>

      <v-toolbar-title v-if="non_t2.length > 0">
        <span class="px-4 caption error--text">* Required Fields</span>
      </v-toolbar-title>

      <v-card-text v-if="non_t2.length > 0">
        <v-row
          v-for="(item, index) in non_t2"
          :key="`nonT2-row-${index}`"
          class="pb-6"
        >
          <v-col cols="12" sm="11">
            <v-row dense>
              <!--Name-->
              <v-col class="py-0" cols="6" sm="6">
                <v-text-field
                  v-model="item.name"
                  label="Name (Last Name, First Name) *"
                  placeholder=" "
                  required
                  :rules="rules.not_empty"
                  :readonly="readonly"
                />
              </v-col>

              <!--Institution-->
              <v-col class="py-0" cols="6" sm="6">
                <v-text-field
                  v-model="item.institution"
                  label="Institution"
                  placeholder=" "
                  :readonly="readonly"
                />
              </v-col>

              <!--Role-->
              <v-col v-if="roleSelector === 1" class="py-0" cols="3" sm="3">
                <v-autocomplete
                  v-model="item.role"
                  :items="valueListsStore.roleValueList"
                  label="Role *"
                  item-text="name"
                  item-value="role"
                  placeholder=" "
                  :clearable="readonly !== true"
                  clear-icon="fal fa-times-circle"
                  required
                  :rules="rules.not_empty"
                  :readonly="readonly"
                />
              </v-col>

              <!--Position-->
              <v-col
                class="py-0"
                :cols="roleSelector === 1 ? 3 : 4"
                :sm="roleSelector === 1 ? 3 : 4"
              >
                <v-autocomplete
                  v-model="item.position"
                  :items="valueListsStore.positionList"
                  label="Position *"
                  placeholder=" "
                  :clearable="readonly !== true"
                  clear-icon="fal fa-times-circle"
                  required
                  :rules="rules.not_empty"
                  :readonly="readonly"
                />
              </v-col>

              <!--Institution Type-->
              <v-col
                class="py-0"
                :cols="roleSelector === 1 ? 3 : 4"
                :sm="roleSelector === 1 ? 3 : 4"
              >
                <v-autocomplete
                  v-model="item.institution_type"
                  :items="valueListsStore.institutionTypeList"
                  label="Institution Type *"
                  placeholder=" "
                  :clearable="readonly !== true"
                  clear-icon="fal fa-times-circle"
                  required
                  :rules="rules.not_empty"
                  :readonly="readonly"
                />
              </v-col>

              <!--Institution Location-->
              <v-col
                class="py-0"
                :cols="roleSelector === 1 ? 3 : 4"
                :sm="roleSelector === 1 ? 3 : 4"
              >
                <v-autocomplete
                  v-model="item.institution_location"
                  :items="valueListsStore.institutionLocationList"
                  label="Institution Location *"
                  placeholder=" "
                  :clearable="readonly !== true"
                  clear-icon="fal fa-times-circle"
                  required
                  :rules="rules.not_empty"
                  :readonly="readonly"
                />
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" sm="1">
            <v-tooltip v-if="usersStore.authUserType !== 4" color="red" bottom>
              <template #activator="{ on }">
                <v-btn
                  :disabled="readonly"
                  icon
                  color="red"
                  v-on="on"
                  @click="removeNonT2(index)"
                >
                  <v-icon>fal fa-minus-circle</v-icon>
                </v-btn>
              </template>
              <span>Remove</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import { createHelpers, mapMultiRowFields } from "vuex-map-fields";

const { mapFields: mapEntryFields } = createHelpers({
  getterType: "participantsStore/getEntryField",
  mutationType: "participantsStore/updateEntryField",
});

const { mapFields: mapFilterFields } = createHelpers({
  getterType: "participantsStore/getFilterField",
  mutationType: "participantsStore/updateFilterField",
});

export default {
  name: "ParticipantsSelector",
  props: {
    // 1 = Researcher, 2 = Trainee
    userType: Number,
    // 1 = Role Selector On, 0 = Role Selector Off
    roleSelector: Number,
    // 1 = Funding, 2 = Publications, 3 = Patents, 4 = Presentations, 5 = Outcomes, 6 = Users Trainees, 7 = project users
    section: Number,
    readonly: Boolean,
  },
  data() {
    return {
      filterMenu: false,
      rules: {
        not_empty: [(v) => !!v || ""],
      },
    };
  },
  computed: {
    ...mapState({
      progressStore: (state) => state.progressStore,
      usersStore: (state) => state.usersStore,
      projectsStore: (state) => state.projectsStore,
      participantsStore: (state) => state.participantsStore,
      valueListsStore: (state) => state.valueListsStore,
    }),

    ...mapEntryFields([
      "show",
      "valid",
      "mode",
      "id",

      "entryState",
      "foundUser",
      "participationID",
      "userID",
      "id_project",
      "researcherID",
      "date_start_month",
      "date_start_year",
      "date_end_month",
      "date_end_year",
      "advisorList",
      "flag_status",
      "flag_contact_pi",
      "researchers",
      "trainees",
      "participants",
      "participant_roles",
    ]),

    ...mapFilterFields([
      "page",
      "id_project",
      "id_funding",
      "id_publication",
      "id_patent",
      "id_presentation",
      "name_first",
      "name_last",

      "researcher_id_user",
      "researcher_name_first",
      "researcher_name_last",

      "trainee_id_user",
      "trainee_name_first",
      "trainee_name_last",

      "other_id_user",
      "other_name_first",
      "other_name_last",

      "type",
    ]),
    ...mapMultiRowFields("participantsStore", [
      "entry.participants_selected",
      "entry.non_t2",
    ]),
  },
  created() {
    this.initialize();
  },
  methods: {
    async initialize() {},

    async findParticipants() {
      let activeProject;
      if (this.section === 7) {
        activeProject = await this.$store.getters["projectsStore/getProject"];
      } else {
        activeProject = await this.$store.getters[
          "usersStore/getActiveProject"
        ];
      }
      this.id_project = activeProject.id;
      if (this.userType === 1) {
        this.type = 1;
        this.trainee_name_first = "";
        this.trainee_name_last = "";
      } else if (this.userType === 2) {
        this.type = 2;
        this.researcher_name_first = "";
        this.researcher_name_last = "";
      }

      let filters = this.$store.getters["participantsStore/getFilters"];

      await this.$store.dispatch("participantsStore/fetch", filters);

      this.filterMenu = false;
    },

    async addParticipant(data) {
      await this.$store.dispatch("participantsStore/addParticipant", data);
      this.clearFilter();
    },

    removeParticipant(index) {
      this.$store.dispatch("participantsStore/removeParticipant", index);
    },

    addNonT2() {
      this.$store.dispatch("participantsStore/addNonT2");
    },

    removeNonT2(index) {
      this.$store.dispatch("participantsStore/removeNonT2", index);
    },

    async clearFilter() {
      if (this.userType === 1) {
        this.researcher_name_first = "";
        this.researcher_name_last = "";
      }
      if (this.userType === 2) {
        this.trainee_name_first = "";
        this.trainee_name_last = "";
      }

      this.findParticipants();
    },

    showFiltered() {
      if (this.userType === 1) {
        if (this.researcher_name_first || this.researcher_name_last)
          return true;
      }
      if (this.userType === 2) {
        if (this.trainee_name_first || this.trainee_name_last) return true;
      }

      return false;
    },
  },
};
</script>

<style scoped>
.description {
  font-size: 16px;
}
</style>
